import React, { useState } from 'react';

const Errors = {
  INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
  BAD_PHONE_NUMBER: '401: Unauthorized',
};

const checkPhoneNumber = (phoneNumberList, phoneNumber) => {
  const strippedPhoneNumber = phoneNumber.replace(/\D/g, '');
  for (let i = 0; i < phoneNumberList.length; i++) {
    const strippedListPhoneNumber = phoneNumberList[i].replace(/\D/g, '');
    if (strippedListPhoneNumber === strippedPhoneNumber) {
      return true;
    }
  }
  return false;
}

const PhoneNumberInput = ({ onSuccess, phoneNumbers }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    let input = event.target.value.replace(/\D/g, '');
    input = input.substring(0, 10);
    if (input.length >= 7) {
      input = input.replace(/^(\d{3})(\d{3})(\d{0,4})$/, '($1) $2-$3');
    } else if (input.length >= 4) {
      input = input.replace(/^(\d{3})(\d{0,3})$/, '($1) $2');
    } else if (input.length >= 1) {
      input = input.replace(/^(\d{0,3})$/, '($1');
    }
    setPhoneNumber(input);
    setError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(phoneNumber, phoneNumber.length)
    if (phoneNumber.length < 14) {
      setError(Errors.INVALID_PHONE_NUMBER);
    } else if (!checkPhoneNumber(phoneNumbers, phoneNumber)) {
      setError(Errors.BAD_PHONE_NUMBER);
    } else {
      onSuccess(phoneNumber);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          style={{ fontSize: "16px" }}
          type="text"
          placeholder="(555) 555-5555"
          value={phoneNumber}
          onChange={handleInputChange}
          onBlur={() => setError(phoneNumber.length < 10)}
          onFocus={() => setError(false)}
        />
        <button style={{ fontSize: "16px" }} type="submit">Enter</button>
      </form>
      {error && <div style={{ color: 'red', fontSize: '12px', position: 'absolute' }}>{error}</div>}
    </div>
  );
};

export default PhoneNumberInput;
