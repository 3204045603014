import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { InviteCard } from "./Invite";
import { Colors } from './colors'

const FlipCardContainer = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
});

const FlipContainer = styled(InviteCard)({ // TODO: improve dependency pattern here
    transformStyle: "preserve-3d",
    transition: "transform 0.5s",
});

const Front = styled("div")({
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const Back = styled(Front)({
    transform: "rotateY(180deg)",
});

const Button = styled("button")({
    padding: "10px 20px",
    marginTop: "2px",
    backgroundColor: Colors.PLUM,
    color: "white",
    border: "none",
    cursor: "pointer",
});

const FlipCard = ({ FrontContent, BackContent }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <FlipCardContainer>
            <FlipContainer style={{ transform: isFlipped ? "rotateY(180deg)" : "none" }}>
                <Front>
                    {FrontContent && <FrontContent />}
                </Front>
                <Back>
                    {BackContent && <BackContent />}
                </Back>
            </FlipContainer>
            <Button onClick={handleFlip}>FLIP</Button>
        </FlipCardContainer>
    );
};

export default FlipCard;