import { useState } from 'react'
import './App.css';
import { styled } from '@mui/system'
import PhoneNumberTextField from './PhoneNumberTextField'
import FlipCard from './FlipCard'
// import FadeInDiv from "./FadeInDiv"
import { InviteFront, InviteBack } from './Invite';
// import BlueHawaiianLyrics from './BlueHawaiianLyrics';
import { Colors } from './colors';

const FullPage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  background: Colors.ORANGE,
})

const Steps = {
  PHONE_NUMBER: 0,
  ALOHA: 1,
  INVITE: 2,
};

const invitees = {
  "4134418556": { name: "Dillon" },
  "9525648272": { name: "Sean" },
  "9082096667": { name: "Meaghan" },
  "3015187384": { name: "Duffy" },
  "4132211976": { name: "Jake" },
  "3109688046": { name: "Max" },
  "9179293292": { name: "Zach" },
  "7248224129": { name: "Hayley" },
  "6268088582": { name: "CJ" },
  "9144827649": { name: "Grace" },
  "6506306888": { name: "Hyde" },
  "6177101732": { name: "Eric" },
  "6464384526": { name: "Kaz" },
  "9782906210": { name: "Julia" },
}

const phoneNumbers = Object.keys(invitees)

// const AudioDiv = styled('div')({
//   position: 'fixed',
//   bottom: 0,
//   left: '50%',
//   transform: 'translateX(-50%)',
// })

// const BlueHawaiianAudio = () => {
//   const audioRef = useRef(null);

//   useEffect(() => {
//     if (!audioRef.current.paused) {
//       audioRef.current.pause();
//       audioRef.current.currentTime = 0;
//     }
//     audioRef.current.currentTime = 86.25;
//     audioRef.current.volume = 0.15;
//     audioRef.current.play();
//   }, []);

//   // TODO audio wrapper where there are hooks to show this component which starts the audio
//   return (
//     <AudioDiv>
//       <audio ref={audioRef} controls>
//         <source src="/BlueHawaiian.mp3" type="audio/mp3" />
//       </audio>
//     </AudioDiv>
//   );
// };

function App() {
  const [step, setStep] = useState(Steps.PHONE_NUMBER)
  const [invitee, setInvitee] = useState(null)

  const phoneNumberOnSuccess = (phoneNumber) => {
    const strippedPhoneNumber = phoneNumber.replace(/\D/g, '');
    setInvitee(invitees[strippedPhoneNumber])
    setStep(Steps.INVITE)
  }

  const StepContent = () => {
    switch (step) {
      case Steps.PHONE_NUMBER:
        return <PhoneNumberTextField onSuccess={phoneNumberOnSuccess} phoneNumbers={phoneNumbers} />;
      // case Steps.ALOHA:
      //   return (
      //     <BlueHawaiianLyrics onComplete={() => { setStep(Steps.INVITE) }} />
      //   )
      case Steps.INVITE:
        // TODO: fix fade in effect
        return (
          // <FadeInDiv>
          <FlipCard FrontContent={() => (<InviteFront invitee={invitee} />)} BackContent={InviteBack} />
          // </FadeInDiv>
        )
      default:
        return <div>TODO ERR</div>;
    }
  }

  return (
    <FullPage>
      <StepContent />
      {/* {(step !== Steps.PHONE_NUMBER) && (
        <BlueHawaiianAudio />
      )} */}
    </FullPage>
  );
}

export default App;
