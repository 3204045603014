import React from "react";
import { styled } from '@mui/system';
import { Typography, Grid } from "@mui/material";
import { Colors } from './colors'

export const InviteCard = styled('div')(({ theme }) => ({
    boxSizing: "border-box",
    width: "60vh",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: '1rem',
    padding: '1rem',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '150vw',
    },
}));

const StyledFrontInviteCard = styled(InviteCard)({
    background: "black",
    color: "white",
})

const EasterIslandEgg = ({ invitee }) => {
    if (invitee.name === "Sean") {
        return (
            <a target="_blank" rel="noreferrer" style={{ 'text-decoration': 'none', color: 'black' }} href="https://open.spotify.com/track/2cPLZlw3CPvvu8Kn13x18p?si=c4a28948647a4f42">🗿</a >
        )
    }
    if (invitee.name === "Max") {
        return (
            <a target="_blank" rel="noreferrer" style={{ 'text-decoration': 'none', color: 'black' }} href="https://youtu.be/SKlfEelgLUs?t=79">🗿</a >
        )
    } else {
        return (<>🗿</>)
    }
}

const InviteHeaderContainer = styled('div')({
    paddingLeft: '1rem',
    paddingRight: '1rem',
})
const FullWidthImage = styled('img')({
    width: '100%',
    height: 'auto',
});

const DateHeader = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 'bold',
    color: Colors.LIGHT_BLUE,

    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));
const PreambleContainer = styled('div')({
    // padding: '3rem',
})
const EventText = styled(Typography)(({ theme }) => ({
    fontSize: '14px',

    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));
const TimeText = styled(EventText)({
    color: 'yellow',
})
const StyledLink = styled('a')({
    color: 'hotpink',
})

const TextRow = ({ time, event }: props) => (
    <>
        <Grid container spacing={0}>
            <Grid item xs={5}>
                <TimeText>{time}</TimeText>
            </Grid>
            <Grid item xs={7}>
                <EventText><em>{event}</em></EventText>
            </Grid>
        </Grid>
    </>
)

export const InviteFront = ({ invitee }) => (
    <StyledFrontInviteCard>
        <InviteHeaderContainer>
            <FullWidthImage src="/aloha-banner.png" />
        </InviteHeaderContainer>
        <PreambleContainer>
            <FullWidthImage src="/handwriting.png" />
        </PreambleContainer>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <center><DateHeader>FRIDAY APRIL 14TH</DateHeader></center>
                <TextRow time="🥟 8PM" event="Dinner at R&G Lounge" />
                <TextRow time="🍹 9PM" event="Drinks at Pagan Idol" />
            </Grid>
            <Grid item xs={6}>
                <center><DateHeader>SATURDAY APRIL 15TH</DateHeader></center>
                <TextRow time="🏄 7:30 AM" event="Surf in Pacifica" />
                <TextRow time={<><EasterIslandEgg invitee={invitee} /> 10:30 AM</>} event="Oceanic Art Tour at the de Young" />
                <TextRow time="🎪 12-6 PM" event={<>Nochella (<StyledLink target="_blank" href="https://partiful.com/e/DPBWondvYIxIrFTR1iZn">Partiful</StyledLink>)</>} />
                <Typography> </Typography>
            </Grid>
        </Grid>
        <DateHeader>
            RSVP via text, join for whatever interests you
        </DateHeader>
    </StyledFrontInviteCard >
);

const StyledBackInviteCard = styled(InviteCard)({
    backgroundColor: Colors.BLUE,
    color: "white",
    border: `2px solid ${Colors.BLUE}`,
})
const EmptyBottomDiv = styled('div')({
    height: '12rem',
    color: Colors.BLUE,
})

export const InviteBack = () => (
    <StyledBackInviteCard>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FullWidthImage src="/aloha-cup.png" />
            </Grid>
        </Grid>
        <FullWidthImage src="/back-handwriting.png" />
        <EmptyBottomDiv>noop teehee</EmptyBottomDiv>
    </StyledBackInviteCard>
);
